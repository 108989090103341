<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />

      <v-snackbar :value="isRefresh" timeout="-1" bottom color="primary">
        {{ $t('common.new_update') }}
        <v-btn color="white" text @click="update">{{ $t('common.refresh') }}</v-btn>
      </v-snackbar>
  </v-app>

</template>

<script>

export default {
  name: 'App',

  components: {
    
  },

  created() {
    document.addEventListener(
      'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
    );
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
        if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        }
      );
    } else {
      console.log("serviceWorker is not supported")
    }
  },

  data: () => ({
      registration:null,
      isRefresh: false,
      refreshing: false,
  }),

  methods:{
    appUpdateUI:function (e){
      this.registration = e.detail;
      this.isRefresh = true;
    },

    update(){
      this.isRefresh = false;
      if (this.registration && (this.registration || this.registration.waiting)) {
        this.registration.waiting.postMessage({type:'SKIP_WAITING'});
      }
    },
  }

};
</script>
