<template>
  <v-toolbar dense flat dark color="grey darken-2">
    <slot/>
  </v-toolbar>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: "grey darken-2"
      },
    },
  }
</script>
