// import i18n from '@/i18n'

export default {

  state: {
    currentLocale: "sl"
  },

  getters: {
    getLocale: (state) => { return state.currentLocale },
  },

  actions: {
  },

  mutations: {
    setLocale (state, payload) {
      state.currentLocale = payload
//       window.i18n.locale = payload
    },
  }
}
