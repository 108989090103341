import { isUndefined } from "@/utils/_"



const optionsDefaults = {
    toastOptions: {
        title:   "",
        delay:   6000,
        position: "top-right",
        queue: true,
    },
}

export default {
    install(vue, opts){
        const options = { ...optionsDefaults, ...opts }

        vue.prototype.$showError = function(error, opts) {
            let toastOptions = {
                type: "error",
                duration: 7000,
            }
            let _options = {...options.toastOptions, ...toastOptions, ...opts}
            let msg = ""

            if (typeof error === "string") {
                msg = error
            }
            // general API error handler
            else if (typeof error === "object" && !isUndefined(error.response)) {

              if (error.response.data.error)
                msg = error.response.data.error
              else if (error.response.data.message)
                msg = error.response.data.message

              switch(error.response.status) {
                // general error
                case 500:
                  break;

                // validation failed
                case 422:
                  if (!isUndefined(error.response.data.errors)) {
                    msg = []
                    msg.push('<p>'+error.response.data.message+'</p>')
                    Object.keys(error.response.data.errors).forEach(function(element) {
                      for (var i in error.response.data.errors[element]) {
                        msg.push('<li>'+error.response.data.errors[element][i]+'</li>')
                      }
                    })
                    msg = msg.join('</br>')
                  }
                  break;

                // unknown route
                case 404:
                  msg = "Route not found"
                  break;
                case 429:
                  msg = "Too Many Requests"
                  break;
              }
            }
            // JS exception handler
            else if (typeof error === "object" && !isUndefined(error.message)) {
                if (!isUndefined(error.name))
                    msg += error.name+": "
                msg += error.message
                console.log(error)
            }
            // unknown error
            else {
              msg = "Unknown server error"
            }


            console.log(msg)
            this.$toast.open(Object.assign({message:msg},_options))
        }

        vue.prototype.$showSuccess = function(response, opts) {
            let toastOptions = {
                type: "success",
            }
            let _options = {...options.toastOptions, ...toastOptions, ...opts}
            let msg = typeof response === "string" ? response : response.data.msg
            this.$toast.open(Object.assign({
              message:msg,
            }, _options))
        }

        vue.prototype.$successDeleted = function(text, opts) {
            let toastOptions = {
                title: "Deleted",
                variant: "success",
            }
            let _options = {...options.toastOptions, ...toastOptions, ...opts}
            this.$bvToast.toast(text,_options)
        }

        vue.prototype.$successAdded = function(text, opts) {
            let toastOptions = {
                title: "Added",
                variant: "success",
            }
            let _options = {...options.toastOptions, ...toastOptions, ...opts}
            this.$bvToast.toast(text,_options)
        }

        vue.prototype.$successEdited = function(text, opts) {
            let toastOptions = {
                title: "Edited",
                variant: "success",
            }
            let _options = {...options.toastOptions, ...toastOptions, ...opts}
            this.$bvToast.toast(text,_options)
        }

        vue.prototype.$successSaved = function(text, opts) {
            let toastOptions = {
                title: "Saved Successfully",
                variant: "success",
            }
            let _options = {...options.toastOptions, ...toastOptions, ...opts}
            this.$bvToast.toast(text,_options)
        }
    }
}
