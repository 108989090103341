import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
// import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";

import i18n  from "@/plugins/i18n";
import store from '@/store'
import axios    from './plugins/axios'
import constants from '@/constants.js'
import { floatDirective } from "@/utils/mixins/customDirectives"
import notify from './utils/notify'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import './registerServiceWorker'


// axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
console.log(process.env.VUE_APP_COMPANY)

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(ToastPlugin);
Vue.use(notify)

Vue.mixin({
  mixins: [floatDirective],

  data: function() {
    return {
      get isDebug() {
        return process.env.NODE_ENV=="development"
      },

      get backendUrl() {
        return process.env.VUE_APP_BACKEND_URL
      },

      get appTitle() {
        return process.env.VUE_APP_TITLE;
      },
//       get darkMode() {
//         if (store.state.auth.currentUser != null && 'theme' in store.state.auth.currentUser)
//         {
//           return store.state.auth.currentUser.theme == "dark"
//         }
//         return false
//       },
      get locales() {
        let langs = []
        let tmp = process.env.VUE_APP_LOCALES ? process.env.VUE_APP_LOCALES.split(",") : []
        for (var i in tmp) {
            let lo = tmp[i]
            langs.push({value:lo, text:i18n.t("locale."+lo)})
        }
        return langs
      },

      get constants() {
        return constants
      }
    }
  }
})

new Vue({
  vuetify,
  store,
  router,
  notify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
