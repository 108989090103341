<template>
  <v-dialog
    v-model="dialogLoader"
    hide-overlay
    persistent
    width="300"
    :attach="attach"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        {{ $t('api.stand_by') }}
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: function() { return null }
      },
      load: {
        type: Boolean,
        default: false,
      },
      itemId: {
        type: [Number,Object,String],
        default: function() { return null }
      },
      callback: {
        type: Function
      },
      defaultValue: {
        type: Object,
        default: function() { return null }
      },
      attach: {
        type: [String,Boolean,Number,Array,Object],
        default: function() { return false }
      }
    },


    data() {
      return {
        dialogLoader: false,
        editedItem: null,
      }
    },

    watch: {
      load(val) {
        if (val == true)
          this.loadContent(this.itemId)
      },
    },

    methods: {
      loadContent(id) {
        if (!id) return
        this.dialogLoader = true

        this.$emit("beforeLoad", id)

        // Lazily load input items
        this.callback(id)
          .then( response => {
            if (response.data)
              this.$emit("input", response.data)
            else
              this.$emit("input", this.defaultValue)
            this.$emit("onLoaded", response.data)
          })
          .catch(err => {
            console.log(err)
            this.$emit("input", this.defaultValue)
            this.$emit("onLoaded", null)
          })
          .finally(() => {
            this.dialogLoader = false
            this.$emit("update:load", false)
          })
      },
    }
  }
</script>
