<template>
  <VueElementLoading :active="value" :spinner="spinner" :color="color"/>
</template>

<script>
  import VueElementLoading        from 'vue-element-loading'

  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },

      spinner: {
        type: String,
        default: "ring"
      },

      color: {
        type: String,
        default: "var(--success)"
      },
    },

    components: {
      VueElementLoading,
    },

    data() {
      return {
//         cntLoader: this.value || false
      }
    },


  }
</script>
