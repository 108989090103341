import {OATH_HEADERS} from '../header.js'
import API from '@/API'
import axios from 'axios'

const events = {
  FIND      : "find",
  FIND_BY_PRC: "findByPrc",
  ACTION    : "action",
  UPDATE    : "update",
}

const getDefaultState = () => {
  return {
    all: [],
  }
}

export default {

  namespaced: true,

  state: getDefaultState(),

  getters: {
  },

  actions: {

    [events.FIND] (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(API.bookings.toma.find+"/"+id, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.FIND_BY_PRC] (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(API.bookings.toma.findByPrc+"/"+id, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ACTION] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.bookings.toma.action, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.UPDATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.bookings.toma.update, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },


  },

  mutations: {
    reset (state) {
      Object.assign(state, getDefaultState())
    },

  }
}


