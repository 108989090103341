<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
        >
        <template v-slot:activator="{ on }">
            <v-text-field ref="edit"
            :autofocus="autofocus"
            autocomplete="off"
            v-model="dateFormatted"
            :label="label"
            :placeholder="placeholder"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :error-messages="errorMessages"
            :readonly="readonly"
            :disabled="disabled"
            v-on="on"
            @input="$emit('input', date)"
            @blur="date = parseDate(dateFormatted); $emit('blur', date)"
            @keyup="$emit('keyup', $event)"
            @click:clear="$emit('clear', date)"
            :clearable="clearable"
            :dense="dense"
            :class="{'x-dense':xDense}"
            :outlined="outlined"
            :hide-details="hideDetails"
            ></v-text-field>
        </template>
        <v-date-picker ref="picker" v-model="date" scrollable @input="menu = false;" :min="min" :max="max" @change="save"
          :first-day-of-week="1" :locale="$i18n.locale"
          show-adjacent-months
        ></v-date-picker>
    </v-menu>
</template>

<script>
  import {dateFormat} from "@/utils/mixins/dateFormat"

  export default {
    mixins: [dateFormat],
    props: {
      value: {
        type: String,
        default: "",
      },

      label: {
        type: String,
        default: "",
      },

      placeholder: {
        type: String,
        default: "",
      },

      clearable: {
        type: Boolean,
        default: false,
      },

      outlined: {
        type: Boolean,
        default: false,
      },

      dense: {
        type: Boolean,
        default: false,
      },

      xDense: {
        type: Boolean,
        default: false,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      min: undefined,
      max: undefined,

      prependIcon: {
        type: String,
        default: null,
      },

      appendIcon: {
        type: String,
        default: null,
      },

      errorMessages: {
        type: Array,
        default: function () {
          return []
        }
      },

      birthdayPicker: {
        type: Boolean,
        default: false,
      },

      readonly: {
        type: Boolean,
        default: false,
      },

      hideDetails: {
        type: Boolean,
        default: false,
      },

      autofocus: {
        type: Boolean,
        default: false,
      },
    },

    model: {
      prop: 'value',
      event: 'input'
    },

    mounted: function () {
      this.$emit('input', this.date)
    },

    data () {
      return {
        menu: false,
        date: this.value,
        dateFormatted: this.formatDate(this.value),
      }
    },

    watch: {
      value (val) {
        this.date = val
      },

      birthdayPicker(val) {
        if (val) {
          if (this.max == undefined) this.max="new Date().toISOString().substr(0, 10)"
          if (this.min == undefined) this.max="1910-01-01"
        }
      },

      menu (val) {
        val && this.birthdayPicker && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },

      date (val) {
        this.$emit('input', this.date)
        this.$emit('change', this.date)
        this.dateFormatted = this.formatDate(this.date)
      }
    },

    methods: {
      save (date) {
        this.$refs.menu.save(date)
      },

      show() {
        this.$refs.edit.focus()
//         this.menu=true
      },
    }

  }
</script>
