import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import VuexPromiseMiddleware from 'vuex-promise-middleware'

import snackbar from './snackbar.js'
import auth from './modules/auth.js'
import localization from './modules/localization.js'
import defstore from './defstore.js'
import crs from './modules/crs.js'
import partners from './modules/partners.js'
import suppliers from './modules/suppliers.js'
import users from './modules/users.js'
import cancelPolices from './modules/cancelPolices.js'
import trips from './modules/trips.js'
import tripsPeriods from './modules/trips.periods.js'
import entryPoints from './modules/entryPoints.js'
import transport from './modules/transport.js'
import tripRooms from './modules/tripRooms.js'
import tripAddons from './modules/tripAddons.js'
import discounts from './modules/discounts.js'
import bookings from './modules/bookings.js'
import bookingMails from './modules/bookingMails.js'
import toma from './modules/toma.js'
import history from './modules/history.js'

Vue.use(Vuex)

const Store = new Vuex.Store({
  actions: {
    clearAll({ commit }){
      commit("auth/reset")
    }
  },
  modules: {
    snackbar: snackbar,
    auth,
    localization,
    defstore,
    crs,
    partners,
    suppliers,
    users,
    cancelPolices,
    trips,
    tripsPeriods,
    entryPoints,
    transport,
    tripRooms,
    tripAddons,
    discounts,
    bookings,
    bookingMails,
    toma,
    history,
  },

  plugins: [createPersistedState()],
})

export default Store

