<template>
  <v-card>
    <v-toolbar dark color="primary" dense>
<!--       <v-toolbar-title>Settings</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-icon @click="$emit('click:close', $event)" v-if="close">mdi-close</v-icon>
    </v-toolbar>

<!--     <v-card-text> -->
      <vue-pdf-app style="height: 100vh;" :pdf="pdfData" ref="mypdf" :file-name="fileName" :config="config"/>
<!--     </v-card-text> -->
  </v-card>
</template>


<script>
  import VuePdfApp from "vue-pdf-app";
  import "vue-pdf-app/dist/icons/main.css";

  import {OATH_HEADERS} from '@/store/header.js'
  import download from 'downloadjs'
  import axios from 'axios'

  export default {
    props: {
      pdfData: {
        type: [String,Object],
        default: null
      },
      download: {
        type: Boolean,
        default: true,
      },
      downloadUrl: {
        type: String,
        default: null
      },
      fileName: {
        type: String,
        default: null,
      },
      close: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      VuePdfApp,
    },
    data() {
      return {
        currentPage: 0,
        pageCount: 0,
        page: 1,

        config: {
//           viewerPrefs: {
//             showPreviousViewOnLoad: false,
//           },
          toolbar: {
            toolbarViewerRight: {
              viewBookmark: false,
              openFile: false,
            },
          }
        },
      }
    },

    watch: {
      pdfData(val) {
        this.$nextTick(()=>this.page=1)
      }
    },

    methods: {
      onDownload() {
        if (this.downloadUrl)
          axios.get(this.downloadUrl, {headers: Object.assign({responseType:"text/plain"},OATH_HEADERS())})
            .then(response=>{
              const content = response.headers['content-type'];
              download(atob(response.data), this.fileName, content)
            })
        else {
          download(this.pdfData.data, this.fileName, "application/pdf")
        }
      }
    },
  }
</script>
