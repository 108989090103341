import {OATH_HEADERS} from '../header.js'
import API from '@/API'
import axios from 'axios'

// import { notify } from '../../utils/notify.js'

const events = {
  LOGIN  : "login",
  LOGOUT : "logout",
  LOGIN_SUCCEEDED : "login_SUCCEEDED",
  REGISTER : "register",
  FORGOT : "forgotPassword",
  RECOVER : "recoverCode",
  RESET  : "resetPassword",
  PASSWORD  : "password",
  THEME: "theme",
  ACTIVATE_USER  : "activateUser",
  RE_ACTIVATION  : "reActivation",
  PROFILE_UPDATE : "profileUpdate",
  ACCOUNT_UPDATE : "accountUpdate",
  SWITCH_ACCOUNT : "switchAccount",

  AUTH: "checkAuth",
  AUTH_FAILED: "auth_FAILED",
  GET_USER_INFO: "getUserInfo",
  GET_ACCOUNT_INFO: "getAccountInfo",
  GET_USER_SUCCEEDED: "getUserInfo_SUCCEEDED",
  GET_ACCOUNT_INFO_SUCCEEDED: "getAccountInfo_SUCCEEDED",
  GET_USER_BASE: "userBase",
  SWTICH_SUCCESS: "switchAccountSuccess",
}

const getDefaultState = () => {
  return {
    tokens: {
      token: null,
      expires_in: null,
    },
    currentUser: {
      imePri: null,
      email: null
    },
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    tokens: (state) => { return state.tokens },
    currentUser: (state) => { return state.currentUser },
    currentAccount: (state) => { return state.currentAccount },
  },

  actions: {
    [events.LOGIN] (context, user) {
      return new Promise((resolve, reject) => {
        console.log(API.login)
        axios.post(API.login, user, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            let now = Date.now()

            responseData.expires_in = responseData.expires_in + now

            context.commit(events.LOGIN_SUCCEEDED, responseData)

            context.dispatch(events.GET_USER_INFO)
//             context.dispatch(events.GET_ACCOUNT_INFO)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.REGISTER] (context, account) {
      return new Promise((resolve, reject) => {
        axios.post(API.register, account, {headers: OATH_HEADERS()})
          .then(response => {
            context.dispatch("updateValidationErrors", [])
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.PROFILE_UPDATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.profile.update, data,{headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_USER_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ACCOUNT_UPDATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.accountUpdate, data,{headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_ACCOUNT_INFO_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.PASSWORD] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.profile.password, data,{headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_USER_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.THEME] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.profile.theme, data,{headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_USER_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ACTIVATE_USER] (context, user) {
      return new Promise((resolve, reject) => {
        axios.post(API.activation, user, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.SWITCH_ACCOUNT] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.switchAccount, data, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
//             context.commit(events.SWTICH_SUCCESS, responseData)
            context.commit(events.GET_ACCOUNT_INFO_SUCCEEDED, responseData.account)
            context.commit(events.GET_USER_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.RE_ACTIVATION] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.reActivation, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.FORGOT] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.forgot, data, {headers: OATH_HEADERS()})
          .then(response => {
            context.dispatch("updateValidationErrors", [])
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.RECOVER] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.recoverCode, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.RESET] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.resetPassword, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.LOGOUT] (context) {
      return new Promise((resolve, reject) => {
        axios.post(API.logout, {},{headers: OATH_HEADERS()})
          .then(response => {
            context.commit(events.LOGIN_SUCCEEDED, {})
            context.dispatch('clearAll'),
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.AUTH] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.checkAuth, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            context.commit(events.AUTH_FAILED)
            reject(error)
          })
      })
    },

    [events.GET_USER_INFO] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.profile.profile, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_USER_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_ACCOUNT_INFO] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.accountInfo, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_ACCOUNT_INFO_SUCCEEDED, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_USER_BASE] (context, user) {
      return new Promise((resolve, reject) => {
        axios.get(API.userBase+"/"+user.id+"/"+user.activation_code, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },

  mutations: {
    reset (state) {
      Object.assign(state, getDefaultState())
    },

    [events.LOGIN_SUCCEEDED] (state, payload) {
      state.tokens = payload
    },
    [events.AUTH_FAILED] (state) {
      state.tokens = []
      state.currentUser = []
    },
    [events.GET_USER_SUCCEEDED] (state, payload) {
      state.currentUser = payload
    },
    [events.GET_ACCOUNT_INFO_SUCCEEDED] (state, payload) {
      state.currentAccount = payload
    },
    [events.SWTICH_SUCCESS] (state, payload) {
      state.currentUser = payload
      state.currentAccount = payload.account
    },
  }
}
