import axios from 'axios'
import store from '@/store'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;


axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error)
  if (error.message && error.message == "Network Error") {
//     store.dispatch('logout')
//     router.push('/login')
  }

  if (error.response && error.response.status === 401) {
    router.push({name:'login'})
  }
  return Promise.reject(error)
})
