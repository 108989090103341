import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

const store_locale = store.getters.getLocale

Vue.use(VueI18n)

/*
* Recursively merge properties of two objects
*/
function MergeRecursive(obj1, obj2) {

  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor==Object ) {
        obj1[p] = MergeRecursive(obj1[p], obj2[p]);

      } else {
        obj1[p] = obj2[p];

      }

    } catch(e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];

    }
  }

  return obj1;
}

function loadLocaleMessages () {
  var messages = {}
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (locale in messages) {
//         messages[locale]  = Object.assign(messages[locale], locales(key))
        messages[locale]  = MergeRecursive(messages[locale], locales(key))
//         messages[locale]  = {...messages[locale], ...locales(key)}
      }
      else
        messages[locale] = locales(key)
    }
  })

  return messages
}

export default new VueI18n({
//   locale: store.getters.getLocale ||  process.env.VUE_APP_I18N_LOCALE || 'en',
  locale: store_locale || process.env.VUE_APP_I18N_LOCALE || 'sl',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'sl',
  messages: loadLocaleMessages()
})
