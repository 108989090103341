<template>
  <v-dialog v-model="dialog" :persistent="persistent" :maxWidth="maxWidth"
  >
    <v-card>
      <v-card-title class="pa-2 info"><span class="title white--text">{{ titleText }}</span></v-card-title>
      <v-card-text class="pt-4">
        <base-alert type="warning" v-if="bodyText">{{ bodyText }}</base-alert>

        <template v-if="type=='delete_destroy' && itemId>0">
          <v-checkbox color="primary" v-model="and_destroy" :label="$t('common.force_delete')" :hint="$t('common.force_delete_hint')" persistent-hint></v-checkbox>
        </template>

        <slot />
      </v-card-text>
      <v-card-actions>
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel" v-if="showCancel">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="green darken-1" text @click="ok" v-if="showOk">{{ $t('common.ok') }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

  export default {
    props: {
      title: {
        type: [String,Number],
        default: null,
      },

      text: {
        type: String,
        default: null
      },

      showCancel: {
        type: Boolean,
        default: true,
      },

      showOk: {
        type: Boolean,
        default: true,
      },

      persistent: {
        type: Boolean,
        default: false,
      },

      maxWidth: {
        type: Number,
        default: 400,
      },

      itemId: {
        type: Number,
        default: null,
      },

      itemData: {
        type: Object,
        default: function() { return null }
      },

      callback: {
        type: Function
      },

      cardProps: {
        type: Object,
        default: function() {
          return {
            color: "transparent",
            light: true,
          }
        }
      },

      // edit, delete, restore
      type: {
        type: String,
        default: "delete",
      },

      color: {
        type: String,
        default: "default",
      },

      show: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      titleText() {
        if (this.title) return this.title
        if (this.itemId) {
          switch( this.type ) {
            case "delete":
            case "delete_destroy":
              return this.$i18n.t('common.delete')+ " #"+this.itemId+' ?'
            case "force_delete":
              return this.$i18n.t('common.force_delete')+ " #"+this.itemId+' ?'
            case "restore":
              return this.$i18n.t('common.restore')+ " #"+this.itemId+' ?'
            case "edit":
              return this.$i18n.t('common.edit')+ " #"+this.itemId+' ?'
          }
        } else {
          switch( this.type ) {
            case "delete":
            case "delete_destroy":
              return this.$i18n.t('common.delete')+ '?'
            case "force_delete":
              return this.$i18n.t('common.force_delete')+ '?'
            case "restore":
              return this.$i18n.t('common.restore')+ '?'
            case "edit":
              return this.$i18n.t('common.edit')+ '?'
          }
        }
        return this.title
      },

      bodyText() {
        if (this.text) return this.text
        if (this.itemId) {
          switch( this.type ) {
            case "delete":
            case "delete_destroy":
              return this.$i18n.t('common.delete_id_confirm', {id:this.itemId})
            case "force_delete":
              return this.$i18n.t('common.force_delete_confirm', {id:this.itemId})
            case "restore":
              return this.$i18n.t('common.restore_id_confirm', {id:this.itemId})
            case "edit":
              return this.$i18n.t('common.edit_id_confirm', {id:this.itemId})
          }
        }

        switch( this.type ) {
          case "delete":
          case "delete_destroy":
            return this.$i18n.t('common.delete_confirm')
          case "force_delete":
            return this.$i18n.t('common.force_delete_confirm')
          case "restore":
            return this.$i18n.t('common.restore_confirm')
          case "edit":
            return this.$i18n.t('common.edit_confirm')
        }
        return this.text
      },
    },

    data() {
      return {
        dialog: this.show || false,
        and_destroy: false,
      }
    },

    watch: {
      show(val) {
        this.dialog = val
      },

      dialog(val) {
        this.$emit("update:show", val)
        if (val)
          this.and_destroy = false
      }
    },

    methods: {
      cancel() {
        this.dialog = false
        this.$emit("onCancel", this.itemId)
      },

      ok() {
        if (this.callback) {
          var id = this.itemId
          var params = id
          if (this.and_destroy)
            params = {id:id, destroy:true}
          this.callback(params)
            .catch(error => {
              console.log(error)
              this.$showError(error,{title:this.$i18n.t('api.error')})
            })
            .finally(() => {
              this.$emit("onOk", this.itemId, this.itemData, this.type, this.and_destroy)
              this.dialog = false
            })
        } else {
          this.$emit("onOk", this.itemId, this.itemData, this.type, this.and_destroy)
          this.dialog = false
        }
      },
    }
  }
</script>
