export default {
    roles: ["sel_admin", "sel_user", "sel_content_edit", "sel_stock_edit", "sel_booking",],
    contentTypes: ["trips","extras"],
    cancelCostsSources: ["catalog","supplier","base","content","not_set"],
    idCards: ["identity_card","passport"],
    sources: ["sellit", "smart", "api"],

    meals: ['meal_breakfast','meal_lunch','meal_diner','meal_all_inclusive'],

    content: {
        icons: {
            trips: "mdi-bus",
            extras: "mdi-cash-register",
            hotel: "mdi-bed",
            offer: "pe-7s-box1",
            catalog: "pe-7s-folder",

            event: "mdi-calendar-check",
            transport: "mdi-train-car",
            entry: "mdi-bus-stop-uncovered",
            tour:  "mdi-routes",
            accomm:  "mdi-bed-outline",
            unit:    "mdi-bed-outline",
        },
        subtypes: {
            trips: ["trip", "plane", "cruise", "busplane", "busplaneship", "train", "charterlet", "notransfer"],
        }
    },

    invRules: ['rq','auto_rq', 'closed', 'arr_closed', 'dep_closed', 'errata', 'release', 'min_stay', 'max_stay'],

    notifyIcons: {
      email: "email",
      push: "announcement",
      sms: "sms",
    },

    seasons: [
      {id:'A', color:'#99fb99'},
      {id:'B', color:'#ffb6c1'},
      {id:'C', color:'#b0c4de'},
      {id:'D', color:'#ead80b'},
      {id:'E', color:'#00ffff'},
      {id:'F', color:'#004f4f'},
      {id:'G', color:'#00557f'},
      {id:'H', color:'#aa5500'},
      {id:'I', color:'#9CC6F5'},
      {id:'J', color:'#F5B694'},
      {id:'K', color:'#550000'},
      {id:'L', color:'#ff0000'},
      {id:'M', color:'#ffaa00'},
      {id:'N', color:'#55ff00'},
      {id:'O', color:'#55007f'},
      {id:'P', color:'#55557f'},
      {id:'Q', color:'#00007f'},
      {id:'R', color:'#ffff7f'},
      {id:'S', color:'#36361b'},
      {id:'T', color:'#492a4f'},
      {id:'U', color:'#351a36'},
      {id:'V', color:'#5f1b2b'},
      {id:'W', color:'#185f1d'},
      {id:'X', color:'#5f5d18'},
      {id:'Y', color:'#5f2917'},
      {id:'Z', color:'#195e5f'},
    ],
}
