import {OATH_HEADERS} from '../header.js'
import API from '@/API'
import axios from 'axios'

const events = {
    GET_COUNTRIES     : "countries",
    GET_TOCS          : "tocs",
    GET_ENTRY_POINTS  : "entryPoints",
    ENTRY_POINTS_SRCH : "entryPointsSearch",
    GET_CITIES        : "cities",
    SEARCH_CITIES     : "citiesSearch",
    GET_REGIONS       : "regions",
    SEARCH_REGIONS    : "regionsSearch",
    GET_REGION_GROUPS : "regionGroups",
    GET_SUBTYPES      : "subtypes",
    GET_ROOM_TYPES    : "roomTypes",
    ROOM_TYPES_SRCH   : "roomTypesSearch",
    SERVICE_TYPES_SRCH   : "serviceTypesSearch",
    ROOM_LOC_SRCH     : "roomLocationSearch",
    ROOM_FAC_SRCH     : "roomFacilitySearch",
    GIATA_SEARCH      : "giatasSearch",
}

const getDefaultState = () => {
  return {
    countries: [],
    cities: [],
    regions: [],
    regionGroups: [],
    tocs: [],
    entryPoints: [],
    subtypes: [],
    roomTypes: [],
  }
}

export default {

  namespaced: true,

  state: getDefaultState(),

  getters: {
    getCountries: (state) => { return state.countries },
    getTocs: (state) => { return state.tocs },
    getEntryPoints: (state) => { return state.entryPoints },
    getCities: (state) => { return state.cities },
    getRegions: (state) => { return state.regions },
    getRegionGroups: (state) => { return state.regionGroups },
    getSubtypes: (state) => { return state.subtypes },
    getRoomTypes: (state) => { return state.roomTypes },

    getEntryPointById: (state) => (id) => {
      return state.entryPoints.find(item => item.id === id)
    },

    getRoomTypeById: (state) => (id) => {
      return state.roomTypes.find(item => item.id === id)
    },

    getSubtypesByCtype: (state) => (ctype) => {
      if(typeof state.subtypes[ctype] != "undefined")
        return state.subtypes[ctype]
      if(typeof state.subtypes["default"] != "undefined")
        return state.subtypes["default"]
      return []
    },
  },

  actions: {

    [events.GET_SUBTYPES] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.subtypes, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_SUBTYPES, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_COUNTRIES] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.countries, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_COUNTRIES, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_ENTRY_POINTS] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.entryPoints, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_ENTRY_POINTS, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ENTRY_POINTS_SRCH] (context, term) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.entryPointsSearch, {term:term}, {headers: OATH_HEADERS()})
          .then(response => {
//             let responseData = response.data
//             context.commit(events.GET_ENTRY_POINTS, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_TOCS] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.tocs, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
//             context.commit(events.GET_TOCS, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_CITIES] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.cities, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.SEARCH_CITIES] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.citiesSearch, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_REGIONS] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.regions, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_REGIONS, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.SEARCH_REGIONS] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.regionsSearch, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_REGION_GROUPS] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.regionGroups, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_REGION_GROUPS, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GET_ROOM_TYPES] (context) {
      return new Promise((resolve, reject) => {
        axios.get(API.crs.roomTypes, {headers: OATH_HEADERS()})
          .then(response => {
            let responseData = response.data
            context.commit(events.GET_ROOM_TYPES, responseData)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ROOM_TYPES_SRCH] (context, term) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.roomTypesSearch, {term:term}, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.SERVICE_TYPES_SRCH] (context, term) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.serviceTypesSearch, {term:term}, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ROOM_LOC_SRCH] (context, term) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.roomLocationSearch, {term:term}, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.ROOM_FAC_SRCH] (context, term) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.roomFacilitySearch, {term:term}, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.GIATA_SEARCH] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.crs.giatasSearch, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },

  mutations: {
    reset (state) {
      Object.assign(state, getDefaultState())
    },

    [events.GET_COUNTRIES] (state, payload) {
      state.countries = payload
    },
    [events.GET_SUBTYPES] (state, payload) {
      state.subtypes = payload
    },
    [events.GET_ENTRY_POINTS] (state, payload) {
      state.entryPoints = payload
    },
    [events.GET_TOCS] (state, payload) {
      state.tocs = payload
    },
    [events.GET_CITIES] (state, payload) {
      state.cities = payload
    },
    [events.GET_REGIONS] (state, payload) {
      state.regions = null
      state.regions = payload
    },
    [events.GET_REGION_GROUPS] (state, payload) {
      state.regionGroups = payload
    },
    [events.GET_ROOM_TYPES] (state, payload) {
      state.roomTypes = payload
    },
  }
}
