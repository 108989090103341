import store from '@/store'

// import i18n from '@/i18n'
//
// const _i18n = i18n

export function OATH_HEADERS() {
  return {
    Accept: 'application/json',
    Authorization: 'Bearer ' + store.state.auth.tokens.token,
    Localization: store.getters.getLocale ||  process.env.VUE_APP_I18N_LOCALE || 'sl',
    "X-AppId": process.env.VUE_APP_ID
  }
}
