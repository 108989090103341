import store from "@/store"

export default function auth({ next, router }) {
  if (!store.getters["auth/tokens"])
    return router.push({ name: 'login' });
  if (!store.getters["auth/tokens"].token) {
    return router.push({ name: 'login' });
  }

  return next();
}
