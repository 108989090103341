import { format, parseISO, getYear, isBefore,
  startOfToday, intervalToDuration
} from 'date-fns';

export const dateFormat = {
  methods: {

    formatDate (date) {
      if (!date) return null

      const [onlyDate, time] = date.split(" ")

      const [year, month, day] = onlyDate.split('-')
      return `${day}.${month}.${year}`
    },

    formatDateTime (date) {
      if (!date) return null
      var d = date instanceof Date ? date : parseISO(date)
      return format(d, "dd.MM.yyyy HH:mm")
    },

    formatTime (date) {
      if (!date) return null
      return format(parseISO(date), "HH:mm")
    },

    formatDateDMY(date) {
      return format(parseISO(date), "dd.MM.yy")
    },

    // same as formatDateDMY, but without year data
    // if year of date is the same as current year.
    formatDateDMY2(date) {
      let today = new Date();
      try {
        let to_date = parseISO(date)
        let this_year = getYear(today)
        let date_year = getYear(to_date)
        if (this_year == date_year)
            return format(parseISO(date), "dd.MM.")
        return format(parseISO(date), "dd.MM.yy")
      }
      catch(e) {
        console.log(e, "date used=",date)
        throw e
      }
    },

    // format proper date from PHP dmY format
    formatDateFromDMY(date) {
      if (date.length==8) {
        const d = date.substring(0, 2)
        const m = date.substring(2, 4)
        const y = date.substring(4, 8)
        return d+"."+m+"."+y
      }
      if (date.length==6) {
        const d = date.substring(0, 2)
        const m = date.substring(2, 4)
        const y = date.substring(4, 6)
        return d+"."+m+"."+y
      }
      return date
    },

    formatDateRange(start, end, delimiter, callfunc) {
      if (start && end)
        return callfunc(start)+delimiter+callfunc(end)
      if (!end)
        return "["+ callfunc(start) + "...]"
      if (!start)
        return "[..."+ callfunc(end) + "]"
    },

    formatDateDM(date) {
      return format(parseISO(date), "dd.MM")
    },

    formatDateMD(date) {
      return format(parseISO(date), "dd. MMM")
    },

    parseDate (date) {
      if (!date) return null
      var newDate = null

      if (date.length < 4) return null
      if (date.length == 4) {
          newDate = `${new Date().getFullYear()}-${date.substring(2,4)}-${date.substring(0,2)}`
      } else if (date.length == 6) {
          let y_now = parseInt(new Date().getFullYear().toString().substring(2,4), 10)
          let y = parseInt(date.substring(4,6), 10)
          if (y > y_now+1)
            y += 1900;
          else
            y += 2000;
          newDate = `${y}-${date.substring(2,4)}-${date.substring(0,2)}`
      } else {
        const [day, month, year] = date.split('.')
        newDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
      this.$emit('input', newDate)
      return newDate
    },

    isOld(date) {
      var d = date instanceof Date ? date : parseISO(date)
      return isBefore(d, startOfToday())
    },

    getDuration(start, end) {
      try {
        let from = parseISO(start)
        let to = parseISO(end)
        return intervalToDuration({start: from, end:to})
      } catch (e) {
        return null
      }
    },
  }
}

