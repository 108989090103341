import {OATH_HEADERS} from '../header.js'
import API from '@/API'
import axios from 'axios'

const events = {
  FIND      : "find",
  SEARCH    : "search",
  CREATE    : "create",
  UPDATE    : "update",
  TOGGLE    : "toggle",
  DELETE    : "delete",
  DOWNLOAD  : "download",
  QUICK_STAT: "quickStat",
}

const getDefaultState = () => {
  return {
    all: [],
  }
}

export default {

  namespaced: true,

  state: getDefaultState(),

  getters: {
    getAll: (state) => { return state.all },

    getById: (state) => (id) => {
      return state.all.find(item => item.id === id)
    },
  },

  actions: {

    [events.SEARCH] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.search, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.DOWNLOAD] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.download, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.FIND] (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(API.content.trips.find+"/"+id, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.CREATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.create, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.TOGGLE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.toggle, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.UPDATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.update, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.DELETE] (context, id) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.delete, {id:id}, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.QUICK_STAT] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.content.trips.quickStat, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },

  mutations: {
    reset (state) {
      Object.assign(state, getDefaultState())
    },

  }
}

