<template>
  <span class="inline-edit">
    <v-overlay absolute :value="loading"><v-progress-circular indeterminate size="20"></v-progress-circular></v-overlay>

    <span class="inline-edit-overlay d-block" v-if="!isEditing" @click="showInput">
      {{ edit!=null?edit:placeholder }}
      <span v-if="percentage">{{ percent ? '%' : '€' }}</span>
    </span>
    <span class="inline-edit-input" v-else>
      <v-text-field v-model="edit" v-float v-if="type=='float'" @blur="onSave" @input="onChange" ref="inlInput" @focus="$event.target.select()"
        :placeholder="placeholder"
        class="d-inline"
        dense hide-details
      />
      <v-text-field v-model="edit" :type="type" v-else @blur="onSave" @input="onChange" ref="inlInput" @focus="$event.target.select()"
        :placeholder="placeholder"
        class="d-inline"
        dense hide-details
      />
      <span v-if="percentage" class="d-inline">
        <v-icon small>{{ percent ? 'mdi-percent-outline' : 'mdi-currency-eur' }}</v-icon>
      </span>
    </span>
  </span>
</template>

<style>
  .inline-edit {
  }
  .inline-edit .inline-edit-overlay{
    height:100%;
    width:100%;
    cursor: text;
  }
  .inline-edit .inline-edit-overlay:hover {
    border: 1px solid currentColor;
    border-radius: 4px;
  }
</style>

<script>
  export default {
    props: {
      value: {
        type: [String,Number],
        default: null
      },

      type: {
        type: String,
        default: "text"
      },

      placeholder: {
        type: [String,Number],
        default: null
      },

      loading: {
        type: Boolean,
        default: false
      },

      editing: {
        type: Boolean,
        default: false
      },

      // if can toggle percentage
      percentage: {
        type: Boolean,
        default: false
      },

      percent: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        edit: this.value,
        isEditing: this.editing,
        hasChanged: false,
      }
    },

    watch: {
      value(val) {
        this.edit = val
      },
    },

    methods: {
      showInput() {
        this.isEditing=true
        this.$emit('update:editing', this.isEditing)
        this.$nextTick(()=>{
          this.$refs.inlInput.focus()
        })
      },

      onChange() {
        this.hasChanged=true
      },

      onSave() {
        if (this.hasChanged) {
          this.$emit("input", this.edit)
        }
        this.isEditing=false
        this.$emit('update:editing', this.isEditing)
        this.hasChanged=false
      },
    }

  }
</script>
