import {OATH_HEADERS} from '../header.js'
import API from '@/API'
import axios from 'axios'

const events = {
  SEARCH    : "search",
}

const getDefaultState = () => {
  return {
    all: [],
  }
}

export default {

  namespaced: true,

  state: getDefaultState(),

  getters: {
  },

  actions: {

    [events.SEARCH] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.history.search, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },


  },

  mutations: {
    reset (state) {
      Object.assign(state, getDefaultState())
    },

  }
}

