  function floatHandlerOnKeyDown(e) {
//             console.log(e.keyCode)
    // change , to . (108 = , on keypad)
    if (e.keyCode == 188  || e.keyCode == 108) {
        e.target.value.slice(0,-1)
        e.target.value+="."
    }

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault()
    }
  }

  export const floatDirective = {

    directives: {
      float: {
        // directive definition
        bind: function (el) {
          el.addEventListener('keydown', floatHandlerOnKeyDown)
        },
        unbind: function() {
          window.removeEventListener('keydown', floatHandlerOnKeyDown)
        },
      }
    },
  }
