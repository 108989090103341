<template>
<div>
  <v-alert v-bind="alertProps"><slot/></v-alert>
</div>
</template>

<script>
  export default {
    name: "Alert",
    props: {
      type: {
        type: String,
        default: null
      },
    },

    computed: {
      alertProps() {
        let def = {
          prominent: false,
          dense: true,
          text: true,
          outlined: true,
          color: "",
          icon: "",
          border: "left",
        }

        if (this.type == "warning") {
          def.color = "deep-orange"
          def.icon  = "mdi-alert-circle-outline"
        }
        if (this.type == "error") {
          def.color = "error"
          def.icon  = "mdi-alert-octagon"
        }
        if (this.type == "info") {
          def.color = "teal"
          def.icon  = "mdi-information-outline"
        }
        if (this.type == "success") {
          def.color = "light-green lighten-1"
          def.icon  = "mdi-check-circle-outline"
        }
        if (this.type == "hint") {
          def.outlined = false
          def.color = ""
          def.icon  = "mdi-information-outline"
        }

        return def
      },
    },
  }
</script>
