export default {

  state: {
    snack: {
        "y": "top",
        "message":""
    },

  },

  getters: {},

  mutations: {
    setSnack (state, snack) {
        state.snack = snack
    }
  }
}